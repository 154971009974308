@keyframes timer {
    from {
        margin-left: 50vw;
        width: 0;
    }
    to {
        margin-left: 0;
        width: 100%
    }
}

.timer {
    height: 3px;
    background-color: white;
    animation-name: timer;
    animation-duration: 10s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}


.overlay-text {
    position: fixed;
    margin-top: 30vh;
    width: 100%;
    background: rgba(0,0,0,0.2);
    font-size: 10vh;
    font-family: 'Space Grotesk', sans-serif;
    color: white;
    z-index: 2;
}